import React from "react";
import PropTypes from "prop-types";
import { Field, reduxForm } from "redux-form";

import { RadioButtonGroup } from "@wfp/ui";

import { Grid, Row, Col } from "Grid";
import SingleDatePickerField from "DatePicker/SingleDatePicker";
import Loading from "Loading";
import RadioButton from "RadioButton";
import Select from "Select";
import Textarea from "Textarea";
import TextInput from "TextInput";
import TextInputNumber from "TextInputNumber";
import {Categories} from "enums";

import {AGWUserSelect} from "./components";
import styles from "./styles.scss";

const formName = "evInfo";

const InfoForm = props => {
  if (props.isLoading) {
    return <Loading />;
  }

  return (
    <>
      <div className={styles.tabWrapper}>
        <form className="wfp-form wfp--form-long">
          <Grid fluid>
            <Row>
              <Col md={12}>
                <Field
                  name="title"
                  label="Title"
                  component={TextInput}
                  readOnly={props.isFieldReadOnly("title")}
                  disabled={props.isFieldDisabled("title")}
                  />
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <Field
                  name="kind"
                  label="Type"
                  options={props.stTypes}
                  component={Select}
                  inputId="kind-infoform"
                  readOnly={props.isFieldReadOnly("kind")}
                  disabled={props.isFieldDisabled("kind")}
                  isClearable={props.isTypeSelected}
                  openMenuOnClick
                  />
              </Col>
              <Col md={3} maxWidth="160px">
                <Field
                  name="exercise"
                  label="Exercise"
                  options={props.exerciseOptions}
                  component={Select}
                  inputId="exercise-infoform"
                  readOnly={props.isFieldReadOnly("exercise")}
                  disabled={props.isFieldDisabled("exercise")}
                  openMenuOnClick
                  />
              </Col>
              <Col md={3} />
              <Col md={3}>
                <Grid>
                  <Row middle="md">
                    <div className={styles.ebSessionFields}>
                      <Col md={5} fixedWidth="135px">
                        <Field
                          cssClass={styles.ebSessionField}
                          name="eb_session_type"
                          label="EB Session"
                          component={Select}
                          readOnly={props.isFieldReadOnly("eb_session")}
                          disabled={props.isFieldDisabled("eb_session")}
                          openMenuOnClick
                          options={[
                                { value: "EB.1", label: "EB.1" },
                                { value: "EB.A", label: "EB.A" },
                                { value: "EB.2", label: "EB.2" }
                              ]}
                          />
                      </Col>
                      <b className={styles.ebSessionSeparator}>/</b>
                      <Col md={4} fixedWidth="88px">
                        <Field
                          cssClass={styles.ebSessionField}
                          readOnly={props.isFieldReadOnly("eb_session")}
                          disabled={props.isFieldDisabled("eb_session")}
                          name="eb_session_year"
                          component={TextInput}
                          placeholder="Year"
                          />
                      </Col>
                    </div>
                  </Row>
                </Grid>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Field
                  label="WFP Evaluation Manager (focal point)"
                  name="manager_wfp_onetoone"
                  inputId="manager-wfp-infoform"
                  readOnly={props.isFieldReadOnly("manager_wfp_onetoone")}
                  disabled={props.isFieldDisabled("manager_wfp_onetoone")}
                  component={AGWUserSelect}
                  category={Categories.CENTRALIZED}
                  />
              </Col>
              <Col md={6}>
                <Field
                  label="QA2"
                  name="qa2_onetoone"
                  inputId="quality-assurance-infoform"
                  readOnly={props.isFieldReadOnly("qa2_onetoone")}
                  disabled={props.isFieldDisabled("qa2_onetoone")}
                  component={AGWUserSelect}
                  category={Categories.CENTRALIZED}
                  />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Field
                  label="Research Analyst"
                  name="research_analyst_wfp_onetoone"
                  inputId="research-analyst-wfp-infoform"
                  readOnly={props.isFieldReadOnly("research_analyst_wfp_onetoone")}
                  disabled={props.isFieldDisabled("research_analyst_wfp_onetoone")}
                  component={AGWUserSelect}
                  category={Categories.CENTRALIZED}
                  />
              </Col>
              <Col md={6}>
                <Field
                  label="Final Approver"
                  name="final_approver_onetoone"
                  inputId="final-approver-infoform"
                  readOnly={props.isFieldReadOnly("final_approver_onetoone")}
                  disabled={props.isFieldDisabled("final_approver_onetoone")}
                  component={AGWUserSelect}
                  category={Categories.CENTRALIZED}
                  />
              </Col>
            </Row>
            <Row>
              <Col md={3} maxWidth="225px">
                <Field
                  name="start_date"
                  index="start_date"
                  label="Start"
                  readOnly
                  disabled={props.isFieldDisabled("start_date")}
                  component={SingleDatePickerField}
                  isTestMode={props.isTestMode}
                  helpText={"Start of preparation phase"}
                />
              </Col>
              <Col md={3} fixedWidth="225px">
                <Field
                  name="planned_completed_date"
                  index="planned_completed_date"
                  label="Planned Completion Date"
                  component={SingleDatePickerField}
                  readOnly={props.isFieldReadOnly("planned_completed_date")}
                  disabled={props.isFieldDisabled("planned_completed_date")}
                  setDatesRange={props.setDatesRange}
                  initialVisibleMonth={props.setInitialVisibleMonth}
                  isTestMode={props.isTestMode}
                  helpText={props.helpTexts.plannedCompletedDate}
                  />
              </Col>
              <Col md={3} fixedWidth="225px">
                <Field
                  name="approval_date"
                  index="approval_date"
                  label="Evaluation Report Approval"
                  component={SingleDatePickerField}
                  disabled={props.isFieldDisabled("approval_date")}
                  setDatesRange={props.setDatesRange}
                  initialVisibleMonth={props.setInitialVisibleMonth}
                  isTestMode={props.isTestMode}
                  readOnly
                  helpText={props.helpTexts.approvalDate}
                  withPortal={false}
                  />
              </Col>
              <Col md={4} fixedWidth="414px">
                <Field
                  name="manager_external"
                  label="External Evaluation Manager"
                  component={TextInput}
                  readOnly={props.isFieldReadOnly("manager_external")}
                  disabled={props.isFieldDisabled("manager_external")}
                  />
              </Col>
            </Row>
            <Row>
              <Col md={2}>
                <RadioButtonGroup
                  name="joint"
                  labelText="Joint"
                  controlled={false}
                  >
                  <Field
                    component={RadioButton}
                    readOnly={props.isFieldReadOnly("is_joint")}
                    disabled={props.isFieldDisabled("is_joint")}
                    name="is_joint"
                    id="is_joint_1"
                    inputId="is_joint"
                    value="is_joint_1"
                    label="Yes"
                    />
                  <Field
                    component={RadioButton}
                    readOnly={props.isFieldReadOnly("is_joint")}
                    disabled={props.isFieldDisabled("is_joint")}
                    name="is_joint"
                    id="is_joint_0"
                    inputId="is_joint"
                    value="is_joint_0"
                    label="No"
                    />
                </RadioButtonGroup>
              </Col>
              <Col md={6}>
                <Field
                  name="joint_partners"
                  inputId="joint_partners"
                  label="Joint Partners"
                  options={props.jointPartnersOptions}
                  component={Select}
                  readOnly={props.isFieldReadOnly("joint_partners")}
                  disabled={props.isFieldDisabled("joint_partners")}
                  allowMultiple
                  openMenuOnClick
                  />
              </Col>
              <Col md={4}>
                <Field
                  name="external_commissioner"
                  inputId="external_commissioner"
                  label="External Commissioner"
                  options={props.externalCommissionerOptions}
                  component={Select}
                  readOnly={props.isFieldReadOnly("external_commissioner")}
                  disabled={props.isFieldDisabled("external_commissioner")}
                  openMenuOnClick
                  />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Field
                  name="topics"
                  label="Topics"
                  options={props.topics}
                  component={Select}
                  readOnly={props.isFieldReadOnly("topics")}
                  disabled={props.isFieldDisabled("topics")}
                  inputId="topics-infoform"
                  allowMultiple
                  openMenuOnClick
                  />
              </Col>
              <Col md={6}>
                <Field
                  name="sustainable_development_goals"
                  label="SDGs"
                  options={props.sustainableDevelopmentGoals}
                  component={Select}
                  readOnly={props.isFieldReadOnly("sustainableDevelopmentGoals")}
                  disabled={props.isFieldDisabled("sustainableDevelopmentGoals")}
                  inputId="sdg-infoform"
                  helpText={props.helpTexts.sustainableDevelopmentGoals}
                  allowMultiple
                  openMenuOnClick
                  />
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <Field
                  name="planned_budget"
                  label="Planned Budget"
                  prependLabel="US $"
                  component={TextInputNumber}
                  readOnly={props.isFieldReadOnly("planned_budget")}
                  disabled={props.isFieldDisabled("planned_budget")}
                  helpText={props.helpTexts.plannedBudget}
                  isTestMode={props.isTestMode}
                  />
              </Col>
              <Col md={3}>
                <Field
                  name="actual_expenditure"
                  label="Actual Expenditure"
                  prependLabel="US $"
                  component={TextInputNumber}
                  readOnly={props.isFieldReadOnly("actual_expenditure")}
                  disabled={props.isFieldDisabled("actual_expenditure")}
                  helpText={props.helpTexts.actualExpenditure}
                  />
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <Field
                  name="publication_reference"
                  label="Publication Reference"
                  component={TextInput}
                  readOnly={props.isFieldReadOnly("publication_reference")}
                  disabled={props.isFieldDisabled("publication_reference")}
                  />
              </Col>
              <Col md={4}>
                <Field
                  name="crosscutting_priorities"
                  label="WFP Cross-Cutting Priorities"
                  options={props.crosscuttingPriorities}
                  component={Select}
                  readOnly={props.isFieldReadOnly("crosscuttingPriorities")}
                  disabled={props.isFieldDisabled("crosscuttingPriorities")}
                  inputId="crosscutting-priorities-infoform"
                  allowMultiple
                  openMenuOnClick
                  smallMenuList
                  />
              </Col>
              <Col md={4}>
                <Field
                  name="request_by_donors"
                  label="Request By Donors"
                  options={props.requestByDonors}
                  component={Select}
                  readOnly={props.isFieldReadOnly("requestByDonors")}
                  disabled={props.isFieldDisabled("requestByDonors")}
                  inputId="request-by-donors-infoform"
                  allowMultiple
                  openMenuOnClick
                  smallMenuList
                  />
              </Col>
            </Row>
            <Row>
              <Col md>
                <Field
                  name="notes"
                  label="Notes"
                  component={Textarea}
                  readOnly={props.isFieldReadOnly("notes")}
                  disabled={props.isFieldDisabled("notes")}
                  enableCounter
                  maxLength={props.notesMaxLength}
                  currentLength={props.notesCurrentLength}
                  />
              </Col>
            </Row>
            <Row>
              <Col md>
                <Field
                  name="description"
                  label="Description"
                  component={Textarea}
                  readOnly={props.isFieldReadOnly("description")}
                  disabled={props.isFieldDisabled("description")}
                  enableCounter
                  maxLength={props.descriptionMaxLength}
                  currentLength={props.descriptionCurrentLength}
                    />
              </Col>
            </Row>
          </Grid>
        </form>
      </div>
    </>
  );
}

InfoForm.propTypes = {
  jointPartnersOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      options: PropTypes.array.isRequired
    })
  ),

  externalCommissionerOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      options: PropTypes.array.isRequired
    })
  ),

  exerciseOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ),
  topics: PropTypes.arrayOf(PropTypes.object).isRequired,
  sustainableDevelopmentGoals: PropTypes.arrayOf(PropTypes.object),
  requestByDonors: PropTypes.arrayOf(PropTypes.object),
  crosscuttingPriorities: PropTypes.arrayOf(PropTypes.object),
  stTypes: PropTypes.arrayOf(PropTypes.object).isRequired,
  isFieldReadOnly: PropTypes.func.isRequired,
  isFieldDisabled: PropTypes.func.isRequired,
  /* eslint-disable */
  initialValues: PropTypes.object,
  /* eslint-enable */
  initialized: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  // date-pickers props
  setDatesRange: PropTypes.func.isRequired,
  setInitialVisibleMonth: PropTypes.func.isRequired,
  // only for test proposes
  isTestMode: PropTypes.bool,
  notesMaxLength: PropTypes.number.isRequired,
  notesCurrentLength: PropTypes.number.isRequired,
  descriptionMaxLength: PropTypes.number.isRequired,
  descriptionCurrentLength: PropTypes.number.isRequired,
  parserToNumberOrNull: PropTypes.func,
  helpTexts: PropTypes.shape({
      approvalDate: PropTypes.string,
      plannedBudget: PropTypes.string,
      plannedCompletedDate: PropTypes.string,
      startYear: PropTypes.string,
      actualExpenditure: PropTypes.string,
      managerWfpOnetoone: PropTypes.string,
      sustainableDevelopmentGoals: PropTypes.string,
  })
};

InfoForm.defaultProps = {
  initialValues: undefined,
  jointPartnersOptions: [],
  sustainableDevelopmentGoals: [],
  requestByDonors: [],
  crosscuttingPriorities: [],
  externalCommissionerOptions: [],
  exerciseOptions: [],
  parserToNumberOrNull: () => null,
  isTestMode: false, // only used for testing purposes
  helpTexts: {}
};

export default reduxForm({
  form: formName,
  /**
   * `enableReinitialize` reinitializes the form whenever `initialValues` changes.
   * Disabled because `initialValues` changes multiple times as we save,
   * and we only want to reinitialize at the end of the saving procedure.
   */
  enableReinitialize: false,
  destroyOnUnmount: true,
  keepDirtyOnReinitialize: false
})(InfoForm);
