import React from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import PropTypes from "prop-types";
import CRUDLayout, {CRUDMapStateToPropsPropTypes, CRUDMapDispatchToProps, CRUDMapDispatchToPropsPropTypes, CRUDMapStateToProps} from "CRUDLayout";
import TableWithModalsMixin from "../../components/TableWithModalsMixin";
import columnsGenerator from "./tableColumns";
import typeActions from "../../actions/types";
import AddTypeModal from "./AddTypeModal";

export class TypeListComponent extends TableWithModalsMixin {
    render = () => (
      <CRUDLayout
        {...this.props}
        title='Types'
        entityName='Type'
        columnsGenerator={columnsGenerator}
        addModalComponent={AddTypeModal}
        getItem={this.getItem}
        handleDragEnd={this.handleDragEnd}
        onSave={this.saveItem}
      />
    );
}

TypeListComponent.propTypes = {
    ...CRUDMapStateToPropsPropTypes,
    ...CRUDMapDispatchToPropsPropTypes,
    data: PropTypes.arrayOf(
      PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string,
          code: PropTypes.string,
          isForCe: PropTypes.bool,
          isForDe: PropTypes.bool,
          isForIe: PropTypes.bool,
          isCeDeprecated: PropTypes.bool,
          isDeDeprecated: PropTypes.bool,
          isIeDeprecated: PropTypes.bool,
      })
    ).isRequired,
    selectedItem: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        code: PropTypes.string,
        isForCe: PropTypes.bool,
        isForDe: PropTypes.bool,
        isForIe: PropTypes.bool,
        isCeDeprecated: PropTypes.bool,
        isDeDeprecated: PropTypes.bool,
        isIeDeprecated: PropTypes.bool,
    }),
    formValues: PropTypes.shape({}),
};

export const mapStateToProps = state => (
  CRUDMapStateToProps(state, 'types', 'exercise.add_kind', 'exercise.change_kind')
)

export const mapDispatchToProps = dispatch => (
  CRUDMapDispatchToProps(dispatch, typeActions, 'types')
);

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(TypeListComponent)
);
