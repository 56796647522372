/*
 * `camelcase` lint option is bothering us way too much
 * since this file needs to speak to the backend
 */
/* eslint-disable camelcase */
import React, { Component } from "react";
import ReactRouterPropTypes from "react-router-prop-types";
import isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { change, formValueSelector, initialize } from "redux-form";

import { get } from "lodash";
import DeleteModal from "Modal/DeleteModal";

import {Categories} from "enums";
import {COVERAGE_ENTITY_TYPE } from "CoverageLocationSelect";
import { history } from "../../../../../../store";
import {
  adaptPartnersOptions,
  capitalize,
  customToastSuccess,
  isCreate,
  parserToNumberOrNull,
} from "../../../../../../utils";
import activityCategoriesActions from "../../../../../../actions/activityCategories";
import divisionsActions from "../../../../../../actions/wfpDivisions";
import officesActions from "../../../../../../actions/wfpOffices";
import partnersActions from "../../../../../../actions/partners.js";
import partnersCategoriesActions from "../../../../../../actions/partnersCategories";
import sdgActions from "../../../../../../actions/sustainableDevelopmentGoals";
import donorsActions from "../../../../../../actions/donors";
import crosscuttingPrioritiesActions from "../../../../../../actions/crosscuttingPriorities";
import WindowsActions from "../../../../../../actions/Windows";
import titlesActions from "../../../../../../actions/titles";
import topicsActions from "../../../../../../actions/topics";
import typesActions from "../../../../../../actions/types";
import { getEvaluationActions } from "../../../../../../actions/evaluations";
import getStatuses from "../../../../../../actions/decentralizedStatuses";

import InfoForm from "./InfoForm";
import CentralizedInfoForm from "./CentralizedInfoForm";
import ImpactInfoForm from "./ImpactInfoForm";
import {getHelpTexts, userFormValueFactory, withResolveOnSettle} from "./utils";
import {InfoFormTransformer} from "./InfoFormTransformer";
import {OptionTransformer} from "./OptionTransformer";
import {ActionButtons, ReasonForCancellation} from "./components";


/**
 * Adapt an evaluation entity (from `entities` state) to form initialValues
 */
const adaptEvaluation = (evaluationId, entities) => {
  // TODO: probably should use page ^ instead of entities in many cases
  const evaluation = entities.evaluations[evaluationId];

  if (typeof evaluation === "undefined") {
    // This means we're creating a new evaluation, so we supply default values.
    return {};
  }

  const activityCategories = (evaluation.activity_categories || []).map(ac => ({
    value: ac.id,
    label: ac.name
  }));

  let jointPartners = [];
  if (evaluation.joint_partners !== undefined) {
    jointPartners = evaluation.joint_partners.map(p => ({
      id: p.id,
      value: p.id,
      label: p.name,
      category: p.category
    }));
  }

  const topics =
    evaluation.topics &&
    evaluation.topics.map(t => ({ value: t.id, label: t.name }));

  const sustainableDevelopmentGoals =
    entities &&
    entities.sustainableDevelopmentGoals &&
    evaluation.sustainable_development_goals &&
    evaluation.sustainable_development_goals
      .map(
        id =>
          entities.sustainableDevelopmentGoals[id] && {
            value: id,
            label: entities.sustainableDevelopmentGoals[id].name,
            position: entities.sustainableDevelopmentGoals[id].position
          }
      )
      .sort((a, b) => a.position > b.position);

    const requestByDonors =
      entities.donors &&
      evaluation?.request_by_donors
        ?.map(
          id =>
            entities.donors[id] && {
              value: id,
              label: entities.donors[id].name,
              position: entities.donors[id].position
            }
        )
        .sort((a, b) => a.position > b.position);

    const fundedByDonors =
      entities.donors &&
      evaluation?.funded_by_donors
        ?.map(
          id =>
            entities.donors[id] && {
              value: id,
              label: entities.donors[id].name,
              position: entities.donors[id].position
            }
        )
        .sort((a, b) => a.position > b.position);

    const crosscuttingPriorities =
      entities.crosscuttingPriorities &&
      evaluation?.crosscutting_priorities
        ?.map(
          id =>
            entities.crosscuttingPriorities[id] && {
              value: id,
              label: entities.crosscuttingPriorities[id].name,
              position: entities.crosscuttingPriorities[id].position
            }
        )
        .sort((a, b) => a.position > b.position);

  const Windows =
      entities.Windows &&
      evaluation?.windows
        ?.map(
          id =>
            entities.Windows[id] && {
              value: id,
              label: entities.Windows[id].name,
              position: entities.Windows[id].position
            }
        )
        .sort((a, b) => a.position > b.position);


  /**
   * Verbose name for either commissioning_office or commissioning_division
   * depending on which one is defined.
   */
  let commissioningOfficeDivision = null;
  if (evaluation.commissioning_office && !isEmpty(entities.wfpOffices)) {
    const office = entities.wfpOffices[evaluation.commissioning_office];
    commissioningOfficeDivision = office ? office.label : "";
  } else if (
    evaluation.commissioning_division &&
    !isEmpty(entities.wfpDivisions)
  ) {
    const division = entities.wfpDivisions[evaluation.commissioning_division];
    commissioningOfficeDivision = division.label;
  }

  let exercise = null;
  if (evaluation.exercise) {
    exercise = {
      label: capitalize(evaluation.exercise.toLowerCase()),
      value: evaluation.exercise,
    };
  }

  // External Commissioner
  let externalCommissioner = null;
  if (
    evaluation.external_commissioner !== undefined &&
    entities.partners[evaluation.external_commissioner]
  ) {
    const extCom = entities.partners[evaluation.external_commissioner];
    externalCommissioner = {
      id: extCom.id,
      value: extCom.id,
      label: extCom.name,
      category: extCom.category
    };
  }

  const coverage = () => {
      const office = entities?.wfpOffices?.[evaluation?.coverage?.[0]?.office];
      const country = entities?.countries?.[evaluation?.coverage?.[0]?.country];
      if (office) {
          return {
              value: office.id,
              entityType: COVERAGE_ENTITY_TYPE.OFFICES,
              label: office.__unicode__,
          }
      }
      if (country) {
          return {
              value: country.id,
              entityType: COVERAGE_ENTITY_TYPE.COUNTRIES,
              label: country.__unicode__,
          }
      }
      return null;
  }

  // Finally, initial form values
  // N.B: values left unprocessed ALSO show up in the adapted evaluation
  const initialValues = {
    ...evaluation, // Evaluation values that were left unprocessed
    activity_categories: activityCategories,
    commissioning_office_division: commissioningOfficeDivision,
    coverage: coverage(),
    eb_session_type: evaluation.eb_session && {
      label: evaluation.eb_session.split("/")[0],
      value: evaluation.eb_session.split("/")[0]
    },
    eb_session_year:
      evaluation.eb_session && evaluation.eb_session.split("/")[1],
    exercise,
    external_commissioner: externalCommissioner,
    is_joint: evaluation.is_joint ? "is_joint_1" : "is_joint_0",
    joint_partners: jointPartners,
    kind: {
      value: evaluation.kind,
      label: get(entities, `types[${evaluation.kind}].name`, "")
    },
    manager_wfp_onetoone: userFormValueFactory(evaluation, entities, 'manager_wfp_onetoone'),
    research_analyst_wfp_onetoone: userFormValueFactory(evaluation, entities, 'research_analyst_wfp_onetoone'),
    qa2_onetoone: userFormValueFactory(evaluation, entities, 'qa2_onetoone'),
    final_approver_onetoone: userFormValueFactory(evaluation, entities, 'final_approver_onetoone'),
    chair_committee_onetoone: userFormValueFactory(evaluation, entities, 'chair_committee_onetoone'),
    sustainable_development_goals: sustainableDevelopmentGoals,
    request_by_donors: requestByDonors,
    funded_by_donors: fundedByDonors,
    crosscutting_priorities: crosscuttingPriorities,
    windows: Windows,
    topics
  };

  return initialValues;
};

export class InfoComponent extends Component {
  componentDidMount() {
    Promise.all([this.fetchData(this.props.match.params.evaluationId)]).then(
      () => {
        Promise.all(
          [
            this.props.fetchEvaluationOptions(),
            this.props.fetchTopics(),
            this.props.fetchDivisions(),
            this.props.fetchOffices(),
            this.props.fetchTitles(),
            this.props.fetchTypes(),
            this.props.fetchPartners(),
            this.props.fetchPartnersCategories(),
            this.props.fetchExerciseOptions(),
            this.props.fetchActivityCategories(),
            this.props.fetchSustainableDevelopmentGoals(),
            this.props.fetchDonors(),
            this.props.fetchCrosscuttingPriorities(),
            this.props.fetchWindows(),
          ].map(promise => withResolveOnSettle(promise))
        ).then(() => {
          this.initializeForm();
        });
      }
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.notesCurrentLength !== this.props.notesCurrentLength) {
      if (this.props.notesMaxLengthReached) {
        const result = this.props.noteValue.slice(0, this.props.notesMaxLength);
        this.props.setNoteValue(result);
      }
    }

    if (prevProps.descriptionCurrentLength !== this.props.descriptionCurrentLength) {
      if (this.props.descriptionMaxLengthReached) {
        const result = this.props.descriptionValue.slice(0, this.props.descriptionMaxLength);
        this.props.setDescriptionValue(result);
      }
    }


    // When `is_joint` is `False` but `joint_partners` or `external_commissioner` are filled
    // we notify the user that `joint_partners` and `external_commissioner` will be removed
    // since these fields can be selected only when `is_joint` is True
    if (!this.props.isJoint && prevProps.isJoint) {
      if (
        this.props.isJointPartnersPopulated ||
        this.props.isExternalCommissionerPopulated
      ) {
        this.props.toggleWarningModal();
      }
    }
  }

  fetchData = evaluationId => {
    if (isCreate(evaluationId)) {
      return new Promise(resolve => resolve());
    }
    return this.props.fetchEvaluation(evaluationId, this.props.category)
  };

  /** Dates range not needed here yet, so this always returns null */
  setDatesRange = () => null;

  /** Initial visible month not needed here yet, so this always return null */
  setInitialVisibleMonth = () => null;

  setFundingPercentage = (fundedValue, entireFunding) => {
      let percentage = '';
      if (fundedValue && entireFunding) {
            percentage = Math.round(fundedValue/entireFunding*100);
      }

      this.props.dispatch(change("evInfo", "subject_funded_percentage", percentage));
  }

  onSubjectFundedChanged = (value) => {
    this.setFundingPercentage(value, this.props.formValues.entire_project_funding);
  }

  onEntireFundedChanged = (value) => {
     this.setFundingPercentage(this.props.formValues.subject_funded_value, value);
  }

  isFieldReadOnly = field => {
    if (!this.props.canEdit) {
      return true;
    }

    // Ask evaluation status which fields are read-only
    const status = this.props.statuses.find(s => s.code === this.props.initialValues.status_code);
    if (status) {
      return status.readonly_fields.includes(field);
    }

    return false;
  };

  isFieldDisabled = field => {
    // Read-only takes precedence over disabled
    if (this.isFieldReadOnly(field)) {
      return false;
    }

    if (!this.props.isJoint && field === "joint_partners") {
      return true;
    }

    if (!this.props.isJoint && field === "external_commissioner") {
      return true;
    }

    // If we are creating a new evaluation, no fields should be disabled
    if (isCreate(this.props.match.params.evaluationId)) {
      return false;
    }

    return false;
  };

  /**
   * (Re)initializes the form with current evaluation data from redux state.
   *
   * Also done after submitting, to align `initialValues` to `values`,
   * thus resetting field dirtyness.
   */
  initializeForm = () => {
    const evaluationId = this.props.evaluation && this.props.evaluation.id;
    this.props.dispatch(
      initialize(
        "evInfo",
        // TODO: maybe use props.initialValues instead of rerunning adaptEvaluation
        adaptEvaluation(evaluationId, this.props.entities, this.props.page),
        // These options refresh values which reach us _after_ first init
        {
          keepDirty: false,
          keepSubmitSucceeded: false,
          updateUnregisteredFields: true,
          keepValues: false
        }
      )
    );
  };

  warningModal = () => (
    <DeleteModal
      confirmLabel="OK"
      cancelLabel="Cancel"
      testId="warning-modal"
      message="Some of the fields that are only applicable for joint evaluations will be deleted.
      Click OK if you want to continue or Cancel if not."
      show={this.props.showWarningModal}
      toggleModal={() => {
        // DeleteModal.props.toggleModal is more of an "onCancel" than a proper toggleModal...
        this.props.dispatch(change("evInfo", "is_joint", "true"));
        this.props.toggleWarningModal();
      }}
      onDelete={() => {
        this.props.dispatch(change("evInfo", "joint_partners", []));
        this.props.dispatch(change("evInfo", "external_commissioner", null));
        this.props.toggleWarningModal();
      }}
    />
  );


  saveEvaluation = params => {
      const adaptedData = InfoFormTransformer.transformToRequestValue(this.props.initialValues, params);
      const actions = getEvaluationActions(this.props.category);

      return this.props.dispatch(
            actions.save(adaptedData, {
                mergeNoArray: true,
                notifySuccess: false,
            })
        ).then(evResponse => {
              const evaluationId = evResponse.value.data.id;

              this.props.dispatch(actions.validation(evaluationId, true))
                .then(() => {
                      this.props.fetchStatuses(
                        this.props.category,
                        this.props.match.params.evaluationId
                      );
                      customToastSuccess();
                      if (isCreate(this.props.match.params.evaluationId)) {
                          history.push(`/evaluations/${evaluationId}/info`);
                          this.fetchData(evaluationId).then(this.initializeForm);
                      } else {
                          this.initializeForm();
                      }
                })
          }
        );
  };

  areActionsDisabled() {
    if (this.props.isFetching) {
      // If prop.isFetching is true, action UI isn't even shown, but let's play safe.
      return true;
    }

    if (this.props.category === Categories.DECENTRALIZED) {
      return (
        !this.props.canEdit ||
        (!this.props.field_office && !this.props.field_division)
      );
    }
    return !this.props.canEdit;
  }

  render() {
    const evFormProps = {
      isLoading: this.props.isFetching,
      exerciseOptions: this.props.exerciseOptions,
      externalCommissionerOptions: this.props.externalCommissionerOptions,
      isFieldReadOnly: this.isFieldReadOnly,
      isFieldDisabled: this.isFieldDisabled,
      jointPartnersOptions: this.props.jointPartnersOptions,
      notesCurrentLength: this.props.notesCurrentLength,
      notesMaxLength: this.props.notesMaxLength,
      descriptionCurrentLength: this.props.descriptionCurrentLength,
      descriptionMaxLength: this.props.descriptionMaxLength,
      onSubmit: params => this.saveEvaluation(params), // Redux-form executes `onSubmit` AFTER form validation (aka `handleSubmit`)
      helpTexts: this.props.helpTexts,
      setDatesRange: this.setDatesRange,
      setInitialVisibleMonth: this.setInitialVisibleMonth,
      stTypes: this.props.stTypes,
      topics: this.props.topicsOptions,
      sustainableDevelopmentGoals: this.props.sdgOptions,
      requestByDonors: this.props.donorOptions,
      fundedByDonors: this.props.donorOptions,
      crosscuttingPriorities: this.props.crosscuttingPrioritiesOptions,
      Windows: this.props.WindowsOptions,
      parserToNumberOrNull,
      isTypeSelected: this.props.isTypeSelected,
    };

    const evForm = () => {
        switch (this.props.category) {
            case Categories.DECENTRALIZED:
                 return (
                   <InfoForm
                     {...evFormProps}
                     fetchOffices={val =>
                        this.props.fetchOffices(val, this.props.officesList)
                     }
                     activityCategoriesOptions={this.props.activityCategoriesOptions}
                     fetchDivisions={this.props.fetchDivisionsDE}
                     onSubjectFundedChanged={this.onSubjectFundedChanged}
                     onEntireFundedChanged={this.onEntireFundedChanged}
                    />
                 )
            case Categories.CENTRALIZED:
                return <CentralizedInfoForm {...evFormProps} />
            case Categories.IMPACT:
                return <ImpactInfoForm {...evFormProps} />
            default:
                return null
        }
    }

    return (
      <div>
        <ActionButtons isDisabled={this.areActionsDisabled()} evaluationId={this.props.evaluation?.id} category={this.props.category} />
        <ReasonForCancellation reasonId={this.props.evaluation?.reason_for_cancellation} />
        {evForm()}
        {this.warningModal()}
      </div>
    );
  }
}

InfoComponent.propTypes = {
  jointPartnersOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      options: PropTypes.array.isRequired
    })
  ),
  externalCommissionerOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      options: PropTypes.array.isRequired
    })
  ),
  exerciseOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ),
  activityCategoriesOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string,
      value: PropTypes.number
    })
  ),
  isExternalCommissionerPopulated: PropTypes.bool,
  isJoint: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
  isJointPartnersPopulated: PropTypes.bool,
  isMigrated: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  match: ReactRouterPropTypes.match.isRequired,
  category: PropTypes.string,
  fetchOffices: PropTypes.func.isRequired,
  fetchDivisions: PropTypes.func.isRequired,
  fetchDivisionsDE: PropTypes.func.isRequired,
  fetchPartners: PropTypes.func.isRequired,
  fetchPartnersCategories: PropTypes.func.isRequired,
  fetchTypes: PropTypes.func.isRequired,
  fetchTitles: PropTypes.func.isRequired,
  fetchTopics: PropTypes.func.isRequired,
  fetchEvaluation: PropTypes.func.isRequired,
  fetchEvaluationOptions: PropTypes.func.isRequired,
  fetchExerciseOptions: PropTypes.func.isRequired,
  fetchActivityCategories: PropTypes.func.isRequired,
  fetchSustainableDevelopmentGoals: PropTypes.func.isRequired,
  fetchCrosscuttingPriorities: PropTypes.func.isRequired,
  fetchWindows: PropTypes.func.isRequired,
  fetchStatuses: PropTypes.func.isRequired,
  fetchDonors: PropTypes.func.isRequired,
  stTypes: PropTypes.arrayOf(PropTypes.object).isRequired,
  topicsOptions: PropTypes.arrayOf(PropTypes.object),
  sdgOptions: PropTypes.arrayOf(PropTypes.object),
  donorOptions: PropTypes.arrayOf(PropTypes.object),
  crosscuttingPrioritiesOptions: PropTypes.arrayOf(PropTypes.object),
  WindowsOptions: PropTypes.arrayOf(PropTypes.object),
  setNoteValue: PropTypes.func.isRequired,
  setDescriptionValue: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  showWarningModal: PropTypes.bool.isRequired,
  toggleWarningModal: PropTypes.func.isRequired,
  /* eslint-disable */
  field_office: PropTypes.any,
  field_division: PropTypes.any,
  evaluation: PropTypes.object,
  formValues: PropTypes.object,
  statuses: PropTypes.array.isRequired,
  initialValues: PropTypes.object,
  initialTopics: PropTypes.array,
  entities: PropTypes.object,
  page: PropTypes.object,
  /* eslint-enable */
  canEdit: PropTypes.bool.isRequired,
  officesList: PropTypes.string.isRequired,
  helpTexts: PropTypes.shape({}),
  noteValue: PropTypes.string,
  notesCurrentLength: PropTypes.number,
  notesMaxLength: PropTypes.number.isRequired,
  notesMaxLengthReached: PropTypes.bool,
  descriptionValue: PropTypes.string,
  descriptionCurrentLength: PropTypes.number,
  descriptionMaxLength: PropTypes.number.isRequired,
  descriptionMaxLengthReached: PropTypes.bool,
  isTypeSelected: PropTypes.bool,
};

InfoComponent.defaultProps = {
  category: undefined,
  evaluation: undefined,
  field_office: undefined,
  field_division: undefined,
  formValues: undefined,
  initialTopics: [],
  initialValues: undefined,
  helpTexts: {},
  noteValue: "",
  descriptionValue: "",
  jointPartnersOptions: [],
  externalCommissionerOptions: [],
  exerciseOptions: [],
  topicsOptions: [],
  sdgOptions: [],
  donorOptions: [],
  crosscuttingPrioritiesOptions: [],
  WindowsOptions: [],
  activityCategoriesOptions: [],
  notesCurrentLength: 0,
  notesMaxLengthReached: false,
  descriptionCurrentLength: 0,
  descriptionMaxLengthReached: false,
  isJointPartnersPopulated: false,
  isExternalCommissionerPopulated: false,
  isTypeSelected: false,
};

export const mapStateToProps = (store, ownProps) => {
  const page = store.pages.evaluation;
  const { entities } = store;
  const offices = store.user.length > 0 && store.user[0].offices;
  const { evaluationId } = ownProps.match.params;
  const selector = formValueSelector("evInfo");

  const evaluation = entities.evaluations[evaluationId];

  // Adapt data to build initialValues first
  const initialValues = adaptEvaluation(evaluationId, entities, page);

  const isMigrated = initialValues && initialValues.migration_code !== null;

  /** Are _any_ fetches being executed? */
  const isFetching =
    page.fetchingActivityCategories ||
    page.fetchingEvaluationsDetailOptions ||
    page.fetchingEvaluations ||
    page.fetchingRequirements ||
    page.fetchingListEvaluations ||
    page.fetchingPartners ||
    page.fetchingPartnersCategories ||
    page.fetchingProjectInfo ||
    page.fetchingSustainableDevelopmentGoals ||
    page.fetchingDonors ||
    page.fetchingCrosscuttingPriorities ||
    page.fetchingWindows ||
    page.fetchingTopics ||
    page.fetchingTypes ||
    page.fetchingUser ||
    page.fetchingUsers ||
    page.fetchingValidation ||
    page.fetchingTitles ||
    page.fetchingWfpUsers;

  const partners =
    page.partners !== undefined &&
    page.partners.map(id => entities.partners[id]).filter(partner => partner.name.toLowerCase() !== 'wfp');
  const partnerCategories =
    page.partnersCategories !== undefined &&
    page.partnersCategories.map(id => entities.partnersCategories[id]);

  let jointPartnersOptions = [];
  /** Options array populated with currently selected joint partners */
  let externalCommissionerOptions = [];

  if (partners !== undefined && partnerCategories !== undefined) {
    jointPartnersOptions = adaptPartnersOptions(partners, partnerCategories);
    const selectedPartners = get(store, "form.evInfo.values.joint_partners");
    if (selectedPartners !== undefined) {
      externalCommissionerOptions = adaptPartnersOptions(
        selectedPartners,
        partnerCategories
      );
    }
  }


  const stTypes = new OptionTransformer(entities.types)
    .customFilter( t =>
        (t.isForCe && ownProps.category === Categories.CENTRALIZED && !t.isCeDeprecated) ||
        (t.isForDe && ownProps.category === Categories.DECENTRALIZED && !t.isDeDeprecated) ||
        (t.isForIe && ownProps.category === Categories.IMPACT && !t.isIeDeprecated))
    .transform();
  // Which topics are NOT selected
  const topicsOptions = new OptionTransformer(entities.topics).transform();
  const sdgOptions = new OptionTransformer(entities.sustainableDevelopmentGoals).transform();
  const donorOptions = new OptionTransformer(entities.donors).transform();
  const crosscuttingPrioritiesOptions = new OptionTransformer(entities.crosscuttingPriorities).transform();
  const WindowsOptions = new OptionTransformer(entities.Windows).transform();
  const activityCategoriesOptions = new OptionTransformer(entities.activityCategories).transform();

  // Extract bool from is_joint radio buttons
  const isJoint = get(store, "form.evInfo.values.is_joint") === "is_joint_1";

  // List of offices available for the user
  const officesList = offices && offices.join();

  const formValues = get(store, "form.evInfo.values");

  const isTypeSelected = Boolean(formValues?.kind?.value)

  const jointPartners = (formValues && formValues.joint_partners) || [];
  const isJointPartnersPopulated = jointPartners.length > 0;

  const externalCommissioner =
    (formValues && formValues.external_commissioner) || [];
  const isExternalCommissionerPopulated =
    externalCommissioner && !Array.isArray(externalCommissioner);

  // Used to calculate characters left
  const noteValue = get(formValues, "notes", "");
  const notesMaxLength = get(page, "options.actions.POST.notes.max_length", 0);
  const notesCurrentLength = (noteValue && noteValue.length) || 0;
  const notesMaxLengthReached = notesCurrentLength >= notesMaxLength;

  const descriptionValue = get(formValues, "description", "");
  const descriptionMaxLength = get(page, "options.actions.POST.description.max_length", 0);
  const descriptionCurrentLength = (descriptionValue && descriptionValue.length) || 0;
  const descriptionMaxLengthReached = descriptionCurrentLength >= descriptionMaxLength;

  return {
    activityCategoriesOptions,
    entities,
    evaluation,
    exerciseOptions: get(page, "exerciseOptions", []),
    externalCommissionerOptions,
    field_division: selector(store, "commissioning_division"),
    field_office: selector(store, "commissioning_office"),
    formValues,
    initialTopics: page.topics,
    initialSdg: page.sustainableDevelopmentGoals,
    initialValues,
    isExternalCommissionerPopulated,
    isFetching,
    isJoint,
    isJointPartnersPopulated,
    isMigrated,
    jointPartnersOptions,
    officesList,
    noteValue,
    notesCurrentLength,
    notesMaxLength,
    notesMaxLengthReached,
    descriptionValue,
    descriptionCurrentLength,
    descriptionMaxLength,
    descriptionMaxLengthReached,
    page,
    helpTexts: getHelpTexts(page?.detailOptions?.actions?.PUT),
    showWarningModal: page.showWarningModal,
    statuses: page.statuses,
    stTypes,
    topicsOptions,
    sdgOptions,
    donorOptions,
    crosscuttingPrioritiesOptions,
    WindowsOptions,
    isTypeSelected,
  };
};

// exported for tests
export const mapDispatchToProps = dispatch => ({
  dispatch, // Needed by the rather complex saveEvaluation method
  fetchExerciseOptions: () =>
    dispatch(getEvaluationActions().exerciseOptions()),
  fetchTopics: () => dispatch(topicsActions.list({}, { paginated: false })),
  fetchTypes: () => dispatch(typesActions.list({}, { paginated: false })),
  fetchType: id => dispatch(typesActions.detail(id)),
  fetchOffices: (val, officesList) =>
    dispatch(
      officesActions.list(
        { search: val, offices_list: officesList },
        { paginated: false }
      )
    ),
  fetchOffice: id => dispatch(officesActions.detail(id)),
  fetchDivision: id => dispatch(divisionsActions.detail(id)),
  fetchDivisions: val =>
    dispatch(divisionsActions.list({ search: val }, { paginated: false })),
  fetchDivisionsDE: val =>
    dispatch(
      divisionsActions.list(
        { code__not: "OEV", search: val },
        { paginated: false }
      )
    ),
  fetchActivityCategories: () =>
    dispatch(activityCategoriesActions.list({}, { paginated: false })),
  fetchSustainableDevelopmentGoals: () =>
    dispatch(sdgActions.list({}, { paginated: false })),
  fetchDonors: () => dispatch(donorsActions.list({}, { paginated: false})),
  fetchCrosscuttingPriorities: () => dispatch(crosscuttingPrioritiesActions.list({}, { paginated: false})),
  fetchWindows: () => dispatch(WindowsActions.list({}, { paginated: false})),
  fetchPartners: () => dispatch(partnersActions.list({}, { paginated: false })),
  fetchPartnersCategories: () =>
    dispatch(partnersCategoriesActions.list({}, { paginated: false })),
  fetchTitles: () => dispatch(titlesActions.list({}, { paginated: false })),
  fetchStatuses: (category, evaluationId) =>
    dispatch(getStatuses({}, category, evaluationId)),
  toggleWarningModal: () =>
    dispatch({ type: "TOGGLE_EVALUATION_WARNING_MODAL" }),
  setNoteValue: value => dispatch(change("evInfo", "notes", value)),
  setDescriptionValue: value => dispatch(change("evInfo", "description", value)),
  fetchEvaluation: (id, category) => dispatch(getEvaluationActions(category).detail(id)),
  fetchEvaluationOptions: () => dispatch(getEvaluationActions().options()),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(InfoComponent)
);
