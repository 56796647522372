import React, {Component} from "react";
import PropTypes from "prop-types";
import ReactRouterPropTypes from "react-router-prop-types";
import {connect} from "react-redux";
import {withRouter, Link} from "react-router-dom";
import {concat, without, includes, get, isEqual} from "lodash";
import moment from "moment";
import {initialize} from "redux-form";
import {Categories} from "enums";
import {
  listEvaluationsActions,
  setEvaluationPage,
} from "../../actions/evaluations";
import officesActions from "../../actions/wfpOffices";
import { getIsUserSupport } from "../../selectors/user";
import search from "../../utils/search";
import {DEFAULT_DATETIME_FORMAT, sortDates} from "../../utils";
import {defaultColumnsSort, sortByPosition,} from "./utils";
import ListComponent from "./ListComponent";
import columnsGenerator from "./columnsGenerator";
import {ListHeader} from "./ListHeader";

// Default visible columns
const defaultVisibleColumns = [
  "category_code",
  "status",
  "publication_reference",
  "title",
  "kind",
  "commissioning"
];

// Columns that shouldn't be available for showing in the table (aka: ignored)
export const excludedColumns = [
  "can_cancel",
  "category",
  "id",
  "internal_code",
  "is_discussed",
  "last_modified_date",
  "last_modified_user",
  "legacy_year",
  "publication_progressive",
  "report_year",
  "publication_year",

  // fields with a custom representation
  "activity_categories",
  "joint_partners",
  "sustainable_development_goals",
  "request_by_donors",
  "funded_by_donors",
  "crosscutting_priorities",
  "windows",
  "topics",

  // these columns are merged into `status` (actually only status_code is used)
  "status_code",
  "status_code_prev",
  "status_original",

  // these columns are merged into `commissioning`
  "commissioning_office",
  "commissioning_division",
  "commissioning_office__id",
  "commissioning_office__parent",
  "commissioning_division__parent",

  // these columns are merged into `analyst`
  "research_analyst",
  "research_analyst_wfp_onetoone",
  "epi_framework",
  'standard_framework',
  "errors_number"
];

// Exported for tests, function to generate filters components
export const generateFilters = (onFilter, filters, setPage) => {
    return [
        {
          label: "CE",
          onClick: () => onFilter({id: "category", value: "centralized"}) && setPage(0),
          active: filters.centralized
        },
        {
          label: "DE",
          onClick: () => onFilter({id: "category", value: "decentralized"}) && setPage(0),

          active: filters.decentralized
        },
        {
          label: "IE",
          onClick: () => onFilter({id: "category", value: "impact"}) && setPage(0),

          active: filters.impact
        },
        {
          label: "Updated by me",
          onClick: () => onFilter({id: "userEvaluations", value: true}) && setPage(0),
          active: filters.userEvaluations
        },
        {
          label: "Editable by me",
          onClick: () => onFilter({id: "editableEvaluations", value: true}) && setPage(0),
          active: filters.editableEvaluations
        },
        {
          label: "Recently updated",
          onClick: () => onFilter({id: "recent", value: true}) && setPage(0),
          active: filters.recent
        }
    ];
}

// ItemsList controlled component
export class ItemsListComponent extends Component {
  componentDidMount() {
    // Retrieve visible columns from localStorage and set redux state with the value
    const savedVisibleColumns = localStorage.getItem(
      "EvaluationVisibleColumns"
    );
    const visible = savedVisibleColumns
      ? savedVisibleColumns.split(",")
      : defaultVisibleColumns;
    this.props.setVisibleColumns(visible);

    // Fetch data from backend
    this.props.fetchEvaluations(this.props.queryParams);
    this.props.evaluationOptions();

    // Fetch offices only when ready
    if (this.props.parentOffices !== "") {
      this.props.fetchOffices(this.props.parentOffices);
    }

    /**
     * Fixes OEV-1142:
     *
     * going from detail to list used to reset `currentPage` to 0
     * because of initializeSearchForm side-effects.
     * So, we store it and reassign it.
     */
    const page = this.props.currentPage;
    Promise.all([
      this.props.initializeSearchForm(this.props.searchInitialValues)
    ]).then(() => {
      this.props.onPageChange(page);
    });
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.queryParams, this.props.queryParams)) {
      this.props.fetchEvaluations(this.props.queryParams);
    }
    // Fetch offices only when ready
    if (prevProps.parentOffices !== this.props.parentOffices) {
      this.props.fetchOffices(this.props.parentOffices);
    }
  }

  onToggleHandler = e => {
    const {value, name} = e;
    // Add or Remove the element from the list
    const visible = value
      ? concat(this.props.visibleColumns, name)
      : without(this.props.visibleColumns, name);
    // Save to localstorage and send to redux
    this.props.setVisibleColumns(visible);
  };

  onRowClick = id => this.props.history.push(`/evaluations/${id}/info`);

  invertSelection = () => {
    const visible = this.props.options
      .map(({value}) => value)
      // Always show category_code, or we won't have a way to modify columns
      .filter(
        o => !this.props.visibleColumns.includes(o) || o === "category_code"
      )
      .concat(["category_code"]);
    this.props.setVisibleColumns(visible);
  };

  isVisible = i => includes(this.props.visibleColumns, i);

  selectAll = () =>
    this.props.setVisibleColumns(this.props.options.map(({value}) => value));

  selectNone = () => this.props.setVisibleColumns(["category_code"]);

  selectDefault = () => this.props.setVisibleColumns(defaultVisibleColumns);

  buildColumns = () => {
    const columns = columnsGenerator(this.props.options, this.isVisible, {
      editColumnsProps: {
        columnsListIsVisible: this.props.showColumnsList,
        hideColsList: this.props.hideColsList,
        showColsList: this.props.showColsList,
        options: this.props.options.filter(o => o.value !== "category_code"),
        visible: this.props.visibleColumns,
        onToggleHandler: this.onToggleHandler,
        onSelectAll: this.selectAll,
        onSelectNone: this.selectNone,
        onSelectDefault: this.selectDefault,
        onInvertSelection: this.invertSelection,
        isUserSupport: this.props.isUserSupport
      }
    }).map(c => ({
      ...c,
      Cell: props => {
        const cell = (get(c, "Cell") && c.Cell(props)) || props.value;
        const url = `/evaluations/${props.original.id}/info`;
        return (
          <Link style={{color: "inherit"}} to={url}>
            {cell}
          </Link>
        );
      }
    }));
    return columns;
  };

  render() {
    return (
      <div>
        <ListHeader
          CECount={this.props.CECount}
          DECount={this.props.DECount}
          IECount={this.props.IECount}
        />
        <ListComponent
          hasBackgroundListRequest={this.props.hasBackgroundListRequest}
          filters={generateFilters(
            this.props.onFilter,
            this.props.filters,
            this.props.onPageChange,
          )}
          // Table props
          options={this.props.options}
          columns={this.buildColumns()}
          fetching={this.props.fetching}
          tableData={this.props.evaluations}
          isExportWarningModalVisible={this.props.isExportWarningModalVisible}
          showExportWarningModal={this.props.showExportWarningModal}
          hideExportWarningModal={this.props.hideExportWarningModal}
          getTdProps={(_, rowInfo) => ({
            onClick: () =>
              rowInfo &&
              this.onRowClick(
                rowInfo && rowInfo.original.id,
                get(rowInfo, "original.category_code")
              ),
            // the color below is the grey-light that you will find in `init.scss`.
            style: {
              color:
                rowInfo &&
                rowInfo.row &&
                rowInfo.original.status_code === "CANCELLED"
                  ? "#bababa"
                  : undefined
            }
          })}
          onSortedChange={sorted => this.props.sortColumn(sorted)}
          sortedColumns={this.props.sortedColumns}
          // Pagination
          onPageChange={page => this.props.onPageChange(page)}
          onPageSizeChange={size => this.props.onPageSizeChange(size)}
          currentPage={this.props.currentPage}
          pageSize={this.props.pageSize}
          // Columns list props
          visibleColumns={this.props.visibleColumns}
          columnsListIsVisible={this.props.showColumnsList}
          onToggleHandler={this.onToggleHandler}
          onSelectAll={this.selectAll}
          onSelectNone={this.selectNone}
          onInvertSelection={this.invertSelection}
          // Test props
          mocked={this.props.mocked}
          // Advanced filters
          addTextFilter={this.props.addTextFilter}
        />
      </div>
    );
  }
}

ItemsListComponent.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  // Method to retrieve evaluation options
  evaluationOptions: PropTypes.func.isRequired,
  isUserSupport: PropTypes.bool.isRequired,
  // List of evaluations to be visualized
  evaluations: PropTypes.arrayOf(PropTypes.object),
  // Method to fetch evaluation data from the list endpoint
  fetchEvaluations: PropTypes.func.isRequired,
  // Columns list stuff
  setVisibleColumns: PropTypes.func.isRequired,
  hideColsList: PropTypes.func.isRequired,
  showColsList: PropTypes.func.isRequired,
  visibleColumns: PropTypes.arrayOf(PropTypes.string),
  showColumnsList: PropTypes.bool.isRequired,
  // Filters
  onFilter: PropTypes.func,
  filters: PropTypes.shape({
    centralized: PropTypes.bool,
    decentralized: PropTypes.bool,
    userEvaluations: PropTypes.bool,
    editableEvaluations: PropTypes.bool,
    recent: PropTypes.bool,
    includeCancelled: PropTypes.bool,
    isJoint: PropTypes.bool
  }),
  // Separate count of DE(centralized) and CE(ntralized) evaluations
  DECount: PropTypes.number,
  CECount: PropTypes.number,
  IECount: PropTypes.number,
  // List of evaluation's fields with their label
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string
    })
  ).isRequired,
  // Whether evaluations are being fetched
  fetching: PropTypes.bool.isRequired,
  hasBackgroundListRequest: PropTypes.bool.isRequired,
  // Params only used for testing purposes
  mocked: PropTypes.bool,

  parentOffices: PropTypes.string,

  showExportWarningModal: PropTypes.func.isRequired,
  hideExportWarningModal: PropTypes.func.isRequired,
  isExportWarningModalVisible: PropTypes.bool.isRequired,

  searchInitialValues: PropTypes.shape({
    query: PropTypes.string
  }),
  initializeSearchForm: PropTypes.func.isRequired,

  addTextFilter: PropTypes.func.isRequired,
  sortColumn: PropTypes.func.isRequired,
  sortedColumns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      desc: PropTypes.bool
    })
  ),

  queryParams: PropTypes.shape({
    commissioning_bureau: PropTypes.number,
    commissioning_division: PropTypes.string,
    commissioning_division_group: PropTypes.string,
    commissioning_office_type: PropTypes.number,
    commissioning_countries: PropTypes.string,
    topics: PropTypes.string,
    partners: PropTypes.string,
    statuses: PropTypes.string,
    type: PropTypes.string,
    approval_years: PropTypes.string,
    start_years: PropTypes.string,
    planned_completed_years: PropTypes.string
  }),

  pageSize: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onPageSizeChange: PropTypes.func.isRequired,
};

ItemsListComponent.defaultProps = {
  evaluations: [],
  DECount: null,
  CECount: null,
  IECount: null,
  mocked: false,
  visibleColumns: defaultVisibleColumns,
  onFilter: undefined,
  filters: [],
  parentOffices: "",
  searchInitialValues: {query: ""},
  sortedColumns: [],
  queryParams: {}
};

const searchInitialValues = advancedFilters => {
  const initialValue = get(advancedFilters, "evaluationSearch.query", "");

  return {query: initialValue};
};

// Exported for tests only
export const mapStateToProps = state => {
  const { entities } = state;
  // TODO: parentOffices check is not safe enough
  const parentOffices =
    state.user.length > 0 ? state.user[0].offices.join() : "";
  const page = state.pages.evaluations;

  // First get the query from the search form
  const query = get(page, "activeAdvancedFilters.evaluationSearch.query", "");
  // Get the list of evaluations, this should include every evaluation (we want to ignore pagination)
  const evaluations = page.items
    .map(id => entities.listEvaluations[id])
    .map(e => ({
      ...e,
      topics_names:
        e.topics &&
        e.topics
          .sort(sortByPosition)
          .map(t => t.name),
      sustainable_development_goals_names:
        e.sustainable_development_goals &&
        e.sustainable_development_goals
          .sort(sortByPosition)
          .map(sdg => sdg.name),
      request_by_donors_names:
        e.request_by_donors &&
        e.request_by_donors
          .sort(sortByPosition)
          .map(donor => donor.name),
      funded_by_donors_names:
        e.funded_by_donors &&
        e.funded_by_donors
          .sort(sortByPosition)
          .map(donor => donor.name),
      crosscutting_priorities_names:
        e.crosscutting_priorities &&
        e.crosscutting_priorities
          .sort(sortByPosition)
          .map(crosscuttingPriorities => crosscuttingPriorities.name),
      windows_names:
        e.windows &&
        e.windows
          .sort(sortByPosition)
            // eslint-disable-next-line camelcase
          .map(windows_names => windows_names.name),
      activity_categories_names:
        e.activity_categories && e.activity_categories.map(ac => ac.name),
      joint_partners_names:
        e.joint_partners && e.joint_partners.map(j => j.name)
    }));

  // remove errors_number from excluded columns if user is admin
  if (getIsUserSupport(state)) {
    const index = excludedColumns.indexOf("errors_number");
    if (index !== -1) {
      excludedColumns.splice(index, 1);
    }
  }

  // Map page options to an array of objects representing columns we want to show
  const options = Object.entries(get(page.options, "actions.POST", {}))
    .filter(i => !excludedColumns.includes(i[0]))
    .map(i => ({label: i[1].label, value: i[0]}))
    .concat([
      {label: "Commissioning", value: "commissioning"},
      {label: "Commissioning Parent", value: "commissioning_parent"},
      {label: "Research Analyst", value: "research_analyst_wfp_onetoone"},
      {label: "Activity Categories", value: "activity_categories_names"},
      {label: "Joint Partners", value: "joint_partners_names"},
      {label: "Topics", value: "topics_names"},
      {label: "SDGs", value: "sustainable_development_goals_names"},
      {label: "Request By Donors", value: "request_by_donors_names"},
      {label: "Funded/Partially funded by donors", value: "funded_by_donors_names"},
      {label: "WFP Cross-Cutting Priorities", value: "crosscutting_priorities_names"},
      {label: "Windows", value: "windows_names"},
    ])
    .sort((a, b) => defaultColumnsSort(a.value, b.value));

  const nullCategory = page.filters.category === null;

  const filters = {
    centralized: nullCategory ? null : page.filters.category === "centralized",
    decentralized: nullCategory
      ? null
      : page.filters.category === "decentralized",
    impact: nullCategory
      ? null
      : page.filters.category === "impact",
    userEvaluations: page.filters.userEvaluations,
    editableEvaluations: page.filters.editableEvaluations,
    recent: page.filters.recent,
    includeCancelled: page.filters.includeCancelled,
    isJoint: page.filters.isJoint
  };

  // Filter results using search query
  // The current user is the only key in entities.user
  // We could organize data better here
  const user =
    entities.user &&
    Object.keys(entities.user)[0] &&
    entities.user[Object.keys(entities.user)[0]].username;
  const searchOptions = options.concat([
    {value: "commissioning_office"},
    {value: "commissioning_office__parent"},
    {value: "commissioning_division"},
    {value: "commissioning_division__parent"},
    {value: "research_analyst"},
    {value: "research_analyst_wfp_onetoone"},
    {value: "request_by_donors"},
    {value: "crosscutting_priorities"},
  ]);
  const searchResults = search(query, evaluations, searchOptions);

  const thirtyDaysAgo = moment().subtract(30, "days");

  // Filtering of evaluations
  const results = searchResults.filter(evaluation => {
    if (filters.centralized && evaluation.category_code !== Categories.CENTRALIZED)
      return false;
    if (filters.decentralized && evaluation.category_code !== Categories.DECENTRALIZED)
      return false;
    if (filters.impact && evaluation.category_code !== Categories.IMPACT)
      return false;
    if (filters.userEvaluations && evaluation.last_group_modified_user !== user)
      return false;
    if (!filters.includeCancelled && evaluation.status_code === "CANCELLED")
      return false;
    if (filters.isJoint && !evaluation.is_joint) return false;
    // Only if user has parent offices the filter is applied
    if (filters.editableEvaluations) {
      if (
        parentOffices &&
        !page.offices.find(
          id => evaluation.commissioning_office__id === id.toString()
        )
      ) {
        return false;
      }
    }
    if (filters.recent) {
      if (
        moment(evaluation.last_group_modified_date, DEFAULT_DATETIME_FORMAT) <
        thirtyDaysAgo
      )
        return false;
    }
    return true;
  });

  // If recent filter data must be sorted by last_group_modified_date desc
  if (filters.recent) {
    results.sort((a, b) => sortDates(a, b));
  }

  const queryParams = Object.values(page.activeAdvancedFilters)
    .map(f => f.queryParams)
    .reduce((prev, next) => ({...prev, ...next}), {});

  const sortedColumns = get(page, "table.columns", []);

  return {
    isUserSupport: getIsUserSupport(state),
    currentPage: page.table.currentPage,
    evaluations: results,
    fetching: page.isFetching,
    hasBackgroundListRequest: Boolean(page.backgroundListRequests),
    filters,
    options,
    pageSize: page.table.pageSize,
    query,
    searchInitialValues: searchInitialValues(page.activeAdvancedFilters),
    // Not really clear, it's the 'page' key referred to pagination in our state's page
    DECount: results.filter(ev => ev.category_code === Categories.DECENTRALIZED).length,
    CECount: results.filter(ev => ev.category_code === Categories.CENTRALIZED).length,
    IECount: results.filter(ev => ev.category_code === Categories.IMPACT).length,
    // Columnslist
    visibleColumns: page.columnsList.visibleColumns,
    showColumnsList: page.columnsList.showList,
    isExportWarningModalVisible: page.showExportWarningModal,
    parentOffices,
    queryParams,
    sortedColumns,
  };
};

// Exported for tests
export const mapDispatchToProps = dispatch => ({
  // Default parameters to avoid backend pagination (not really, but good enough for now)
  // and ordering by last created (more or less, by '-id' actually)
  fetchEvaluations: params => {
    const pageSize = 100;
    dispatch({type: "CLEAR_ITEMS", resource: "listEvaluations"});
    dispatch(
      listEvaluationsActions.list({
        ...params,
        page: 1,
        pageSize,
      }, {appendItems: true})
    ).then(response => {
      for (let i = pageSize; i < response.value.data.count; i += pageSize) {
        dispatch(
          listEvaluationsActions.listInBackground({
            ...params,
            page: i/pageSize + 1,
            pageSize,
          }, { appendItems: true })
        );
      }
    })
  },
  // Fetch metadata needed to build the columns list
  evaluationOptions: () => dispatch(listEvaluationsActions.options()),
  // This will set the current paginator page
  // Export Warning Modal
  showExportWarningModal: () => dispatch({type: "SHOW_EXPORT_WARNING_MODAL"}),
  hideExportWarningModal: () => dispatch({type: "HIDE_EXPORT_WARNING_MODAL"}),
  // ListComponent stuff
  setVisibleColumns: visible => {
    localStorage.setItem(
      "EvaluationVisibleColumns",
      visible.length === 0 ? ["NONE"] : visible
    );
    return dispatch({type: "EVALUATION_SET_VISIBLE", visible});
  },
  onToggleColsList: () => dispatch({type: "EVALUATION_TOGGLE_COLS_LIST"}),
  hideColsList: () => dispatch({type: "EVALUATION_HIDE_COLS_LIST"}),
  showColsList: () => dispatch({type: "EVALUATION_SHOW_COLS_LIST"}),
  onFilter: data =>
    Promise.all([
      dispatch({type: "FILTER_TOGGLE", data}),
      dispatch(setEvaluationPage(0))
    ]),
  onPageChange: page => dispatch({type: "SET_PAGE", data: page}),
  onPageSizeChange: size => dispatch({type: "SET_PAGE_SIZE", data: size}),
  addTextFilter: (filterName, filter) =>
    Promise.all([
      dispatch({type: "ADD_TEXT_FILTER", filterName, filter}),
      dispatch({type: "SET_PAGE", data: 0})
    ]),
  fetchOffices: parentOffices =>
    dispatch(
      officesActions.list({ offices_list: parentOffices }, { paginated: false })
    ),
  // Needed to manually to refresh the advanced filters form
  initializeSearchForm: data => dispatch(initialize("search", data)),
  sortColumn: data => dispatch({type: "SORT_COLUMN", data})
});

// We wrap the component inside 'withRouter' so we can have
// the 'history' prop that will allow us to call navigation actions
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ItemsListComponent)
);
